import { defineMessages } from 'react-intl';

export const {
  step1,
  step1Desc,
  step1AlbumsDesc,
  step1LikedSongsDesc,
  step1Alt,
  step2,
  step2Desc,
  step2Alt,
  step3,
  step3Desc,
  step3Alt,
  step4,
  step4Desc,
  step4ArtistsDesc,
  step4Alt,
  step1AllMusicDesc,
  step1ArtistsDesc,
  step2AlbumsDesc,
  step2LikedSongsDesc,
  step2AllMusicDesc,
  step2ArtistsDesc,
  step3AlbumsDesc,
  step3LikedSongsDesc,
  step3AllMusicDesc,
  step3ArtistsDesc,
  step4PlaylistsDesc,
  step4AlbumsDesc,
  step4LikedSongsDesc,
  step3LikedSongs,
  step3Albums,
  step3AllMusic,
  step3Artists
} = defineMessages({
  step1: {
    id: 'web.move.how-to.step1',
    defaultMessage: 'Select {from} as a source'
  },
  step1Desc: {
    id: 'web.move.how-to.step1-desc',
    defaultMessage: 'Use your current {from} platform as a source and start to transfer playlists with FreeYourMusic.'
  },
  step1AlbumsDesc: {
    id: 'web.move.how-to.step1-albums-desc',
    defaultMessage: 'Use your {from} account as a source and move albums with FreeYourMusic.'
  },
  step1LikedSongsDesc: {
    id: 'web.move.how-to.step1-liked_songs-desc',
    defaultMessage: 'Using your {from} account transfer liked songs with FreeYourMusic.'
  },
  step1AllMusicDesc: {
    id: 'web.move.how-to.step1-all_music-desc',
    defaultMessage: 'Using your {from} account move all music library with FreeYourMusic.'
  },
  step1ArtistsDesc: {
    id: 'web.move.how-to.step1-artists-desc',
    defaultMessage: 'Use your {from} account as a source and move artists with FreeYourMusic.'
  },
  step1Alt: {
    id: 'web.move.how-to.step1-alt',
    defaultMessage: 'Step 1: Select {from} as a source'
  },
  step2: {
    id: 'web.move.how-to.step2',
    defaultMessage: 'Select {to} as a destination'
  },
  step2Desc: {
    id: 'web.move.how-to.step2-desc',
    defaultMessage:
      'Select {to} as a platform where you want to create a new playlist. All you need to do is just one button click!'
  },
  step2AlbumsDesc: {
    id: 'web.move.how-to.step2-albums-desc',
    defaultMessage:
      'Select {to} as a platform where you want to transfer albums. All you need to do is just one button click!'
  },
  step2LikedSongsDesc: {
    id: 'web.move.how-to.step2-liked_songs-desc',
    defaultMessage: 'Select {to} as a platform where you want to import liked songs. Just one button click away!'
  },
  step2AllMusicDesc: {
    id: 'web.move.how-to.step2-all_music-desc',
    defaultMessage: 'Select {to} as a platform where you want to import all music library. Just one tap away!'
  },
  step2ArtistsDesc: {
    id: 'web.move.how-to.step2-artists-desc',
    defaultMessage:
      'Select {to} as a platform where you want to transfer artists. All you need to do is just one button click!'
  },
  step2Alt: {
    id: 'web.move.how-to.step2-alt',
    defaultMessage: 'Step 2: Select {to} as a destination music platform'
  },
  step3: {
    id: 'web.move.how-to.step3',
    defaultMessage: 'Select playlists for a transfer'
  },
  step3Albums: {
    id: 'web.move.how-to.step3-albums',
    defaultMessage: 'Select albums for a transfer'
  },
  step3LikedSongs: {
    id: 'web.move.how-to.step3-liked_songs',
    defaultMessage: 'Select liked songs for a transfer'
  },
  step3AllMusic: {
    id: 'web.move.how-to.step3-all_music',
    defaultMessage: 'Select playlists, albums, liked songs for a transfer'
  },
  step3Artists: {
    id: 'web.move.how-to.step3-artists',
    defaultMessage: 'Select artists for a transfer'
  },
  step3Desc: {
    id: 'web.move.how-to.step3-playlists-desc',
    defaultMessage:
      'Find playlists you want to transfer from {from} service to {to} and select all the playlists you want to move.'
  },
  step3AlbumsDesc: {
    id: 'web.move.how-to.step3-albums-desc',
    defaultMessage:
      'Find albums you want to transfer from {from} service to {to} and select all the albums you want to move.'
  },
  step3LikedSongsDesc: {
    id: 'web.move.how-to.step3-liked_songs-desc',
    defaultMessage: 'Select your favorite tracks to transfer from {from} to {to}.'
  },
  step3AllMusicDesc: {
    id: 'web.move.how-to.step3-desc',
    defaultMessage:
      'Find playlists you want to transfer from {from} service to {to} and select all the playlists you want to move.'
  },
  step3ArtistsDesc: {
    id: 'web.move.how-to.step3-artists-desc',
    defaultMessage:
      'Find artists you want to transfer from {from} service to {to} and select all the artists you want to move.'
  },
  step3Alt: {
    id: 'web.move.how-to.step3-alt',
    defaultMessage: 'Step 3: Select playlists from the music library'
  },
  step4: {
    id: 'web.move.how-to.step4',
    defaultMessage: 'Confirm your action and done!'
  },
  step4Desc: {
    id: 'web.move.how-to.step4-desc',
    defaultMessage:
      'The transfer is finished. Your {from} songs and playlists have been moved to {to} and you can start enjoying your new music streaming service.'
  },
  step4ArtistsDesc: {
    id: 'web.move.how-to.step4-artists-desc',
    defaultMessage:
      'The transfer is finished. Your {from} artists have been moved to {to} and you can start enjoying your new music streaming service.'
  },
  step4PlaylistsDesc: {
    id: 'web.move.how-to.step4-playlists-desc',
    defaultMessage:
      'The transfer is finished. Your {from} playlists have been moved to {to} and you can start enjoying your new music streaming service.'
  },
  step4AlbumsDesc: {
    id: 'web.move.how-to.step4-albums-desc',
    defaultMessage:
      'The transfer is finished. Your {from} albums have been moved to {to} and you can start enjoying your new music streaming service.'
  },
  step4LikedSongsDesc: {
    id: 'web.move.how-to.step4-liked_songs-desc',
    defaultMessage:
      'The transfer is finished. Your {from} liked songs have been moved to {to} and you can start enjoying your new music streaming service.'
  },
  step4Alt: {
    id: 'web.move.how-to.step4-alt',
    defaultMessage: 'Step 4: Confirm to transfer {from} playlists and  move it to {to}'
  }
});
